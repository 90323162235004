import React from 'react';
import { Spinner } from 'react-bootstrap';

const menu = (color: string, size: string) => <i aria-hidden className={'fas fa-bars ' + size} style={{ color: color }} />;

const search = (color: string, size: string) => <i aria-hidden className={'fa fa-search ' + size} style={{ color: color }} />;

const cart = (color: string, size: string) => <i aria-hidden className={'fas fa-shopping-cart ' + size} style={{ color: color }} />;

const remove = (color: string, size: string) => <i aria-hidden className={'far fa-trash-alt ' + size} style={{ color: color }} />;

const close = (color: string, size: string) => <i aria-hidden className={'fas fa-times ' + size} style={{ color: color }} />;

const negetive = (color: string, size: string) => <i aria-hidden style={{ color: color }} className={'fas fa-minus ' + size} />;

const plus = (color: string, size: string) => <i aria-hidden style={{ color: color }} className={'fa fa-plus ' + size} />;

const loading = (color: string, size: string) => (
  <i style={{ color: color }} aria-hidden className={'fas fa-circle-notch fa-spin ' + size} />
);
const info = (color: string, size: string) => (
  
  <span className="fa-stack">
  <i style={{ color: color }} aria-hidden className={"fa fa-circle-thin fa-stack-2x"+ size}></i>
  <i style={{ color: color }} aria-hidden className={'fa fa-info fa-stack-1x' + size} />
  </span>
);

const phone = (color: string, size: string) => <i aria-hidden style={{ color: color }} className={'fa fa-phone' + size} />;

const email = (color: string, size: string) => <i aria-hidden style={{ color: color }} className={'far fa-envelope' + size} />;
const pulseLoader = (color: string, size: string) => (
  <i aria-hidden style={{ color: color }} className={'fas fa-spinner fa-pulse ' + size} />
);

const downArrow = (color: string, size: string) => <i aria-hidden style={{ color: color }} className={'fas fa-chevron-down arrow-downxs' + size} />;

const Loader = (size: string) => <Spinner animation="grow" variant="secondary" className={size} />;

const folder = (color: string, size: string) => <i aria-hidden style={{ color: color }} className={'fas fa-folder ' + size} />;

const tags = (color: string, size: string) => <i aria-hidden style={{ color: color }} className={'fas fa-tags ' + size} />;

const comments = (color: string, size: string) => <i aria-hidden style={{ color: color }} className={'fas fa-comments ' + size} />;

const arrow = (color: string, size: string) => <i aria-hidden style={{ color: color }} className={'fas fa-chevron-left ' + size} />;

const threeD = (color: string, size: string) => <i aria-hidden style={{ color: color }} className={'fas fa-cube ' + size} />;

const up = (color: string, size: string) => <i aria-hidden style={{color:color}} className={'fas fa-caret-up ' + size}></i>
const dragDropIcon = () => {
  return  <svg viewBox="0 0 1024 1024" focusable="false" className="" data-icon="inbox" width="50px" height="50px" fill="#B1B1B1" aria-hidden="true"><path d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z"></path></svg>                 
}

const icons = {
  menu,
  search,
  cart,
  close,
  negetive,
  plus,
  remove,
  loading,
  folder,
  tags,
  comments,
  arrow,
  pulseLoader,
  phone,
  email,
  Loader,
  up,
  dragDropIcon,
  info,
  threeD,
  downArrow
};

export default icons;
