const serviceUrl = process.env.NEXT_PUBLIC_BACKEND_URL;

const config = {
  baseUrl: "",
  product: 'product',
  serviceUrlMain:serviceUrl,
  searchParam: 's',
  endpoints: {
    notFound: serviceUrl + '/page-not-found',
    search: serviceUrl + '/search',
    homepage: serviceUrl + '/homepage',
    products: serviceUrl + '/products',
    landing: serviceUrl + '/n3e-landing',
    landing2: serviceUrl + '/n3e-landing-2',
    sporttowel: serviceUrl + '/seat-cover-sports-towel',
    bundle: serviceUrl + '/n3-elite-bundles',
    connect: serviceUrl + '/connect-service-request',
    connectgeneral: serviceUrl + '/connect-general-info',
    getCart: serviceUrl + '/anoncart',
    productBundle: serviceUrl + '/product-bundles',
    checkout: serviceUrl + '/checkout',
    country: serviceUrl + '/countries',
    countryStates: serviceUrl + '/states',
    subscriptions: serviceUrl + '/subscriptions',
    testimonials: serviceUrl + '/testimonials',
    faq: serviceUrl + '/faq',
    trainingVideo: serviceUrl + '/training-videos',
    manuals: serviceUrl + '/manuals',
    review: 'reviews',
    payments: serviceUrl + '/payments',
    orders: serviceUrl + '/orders/search/findByOrderId',
    connectConfirmation: serviceUrl + '/connect-confirmation',
    blog: serviceUrl + '/blogs',
    posts: serviceUrl + '/posts',
    blogList: serviceUrl + '/blog-list',
    orderConfirmation: serviceUrl + '/order-confirmation',
    privacyPolicy: serviceUrl + '/privacy-policy',
    militaryFirst: serviceUrl + '/military-first-responder-discount',
    termsOfUse: serviceUrl + '/terms-of-use',
    update: serviceUrl + '/update',
    customer: serviceUrl + '/customers',
    returnPolicy: serviceUrl + '/return-policy',
    accessibility: serviceUrl + '/accessibility',
    tshirtform: serviceUrl + '/t-shirt-form',
    heroesDiscount: serviceUrl + '/heroes-discount',
    leadForm: serviceUrl + '/n-3-lead-form-page',
    customerServiceServey: serviceUrl + '/customer-service-survey',
    customerServey1: serviceUrl + '/customer-survey-1',
    customerServey2: serviceUrl + '/customer-survey-2',
    customerServey3: serviceUrl + '/customer-survey-3',
    cbtCustomerServey1: serviceUrl + '/cross-body-trainer-customer-survey-1',
    leaveReviewForm: serviceUrl + '/leave-a-review-form',
    returnRequest: serviceUrl + '/return-request',
    redirects: serviceUrl + '/redirect-links',
    CrossBodybundle: serviceUrl + '/cross-body-trainer-bundles',
    educatordiscount: serviceUrl + '/educator-discount',
    nowarrantyApplication: serviceUrl +  '/no-warranty-application',
    getExtendWarrantyType: serviceUrl + '/products/findByTypeOrName',
    
    
  },
  blogListPagination: 10,
  blogHomePagination: 9,
  searchPagination: 10,
  pageNotFoundTitle: 'Oops! That page can’t be found.',
  pageNotFoundDescription: 'It looks like nothing was found at this location. Maybe try searching?',
  searchNotFoundTitle: 'Nothing Found',
  searchNotFoundDescription:
    'Sorry, but nothing matched your search terms. Please try again with some different keywords.'
};

export default config;
